
import { defineComponent, computed } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
  },
  setup() {
    // @ts-ignore
    const { $store } = useNuxtApp();

    const isStaticHeroVariant = computed(() => {
      const test = $store.getters.getExperimentById('0005_static_hp_hero');
      return test?.variant === '1';
    });

    return {
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
      mp4Video: 'https://cdn-b.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.mp4',
      webmVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.webm',
      isStaticHeroVariant,
      pictureVideoComponent: {
        _uid: 'c0932db2-94f2-46da-a553-02845ff6cfa3fff',
        Image: {
          id: 16413978,
          alt: 'Punching FightCamp Bag in Home Gym',
          name: '',
          focus: '',
          title: '',
          source: '',
          filename: 'https://a.storyblok.com/f/152150/1512x946/0aff2ff33e/fchome_desktop.png',
          copyright: '',
          fieldtype: 'asset',
          meta_data: {},
          is_private: false,
          is_external_url: false,
        },
        codeId: '',
        component: 'PictureResponsive',
        imageType: '',
        _editable: '<!--#storyblok#{"name": "PictureResponsive", "space": "152150", "uid": "c0932db2-94f2-46da-a553-02845ff6cfa3", "id": "196275687"}-->',
      },
    };
  },
});
