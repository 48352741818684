
import { defineComponent, computed } from '@vue/composition-api';
import { FiveStars } from '@fc/angie-ui';
import { IStoryblokVideo } from '~/selectors/types';
import TestimonialCarousel from '../TestimonialCarousel.vue';
import TestimonialSlide from '../TestimonialSlide.vue';

// TODO refine once receive the actual data/assets
export interface ITestimonialData {
  id: string;
  videoPreview: {
    url: string;
    alt: string;
  };
  customerIcon: {
    filename: string;
    alt: string;
  };
  customerName: string;
  memberSince: string;
  video: IStoryblokVideo;
}

const testimonialData = [
  {
    id: '001',
    videoPreview: {
      url: 'https://a.storyblok.com/f/152150/2160x3840/ccff26bf5a/fc-review-jenn-preview-001.png',
      alt: "FightCamp user jenn's testimonial video",
    },
    customerIcon: {
      filename: 'https://a.storyblok.com/f/152150/736x802/0867157042/fc-member-profile.jpg',
      alt: 'Customer Icon',
    },
    customerName: 'Jenn',
    memberSince: 'Member since Nov 2023',
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: false,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-jenn-review-002.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: "FightCamp user jenn's testimonial video",
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/608x1080/7b1595722a/fc-member-jenn-review-first-frame-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-jenn-review-002.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
      _editable: '<!--#storyblok#{"name": "Video", "space": "152150", "uid": "fe7ea043-9f69-41ed-b507-cd47e55e9ee5", "id": "235920305"}-->',
    },
  },
  {
    id: '002',
    videoPreview: {
      url: 'https://a.storyblok.com/f/152150/2160x3840/a9c6f77175/fc-review-alex-preview-001.png',
      alt: "FightCamp user alex's testimonial video",
    },
    customerIcon: {
      filename: 'https://a.storyblok.com/f/152150/796x700/6be9868d90/fc-member-alex-profile.jpg',
      alt: 'Customer bunching FightCamo bag with gloves on',
    },
    customerName: 'Alex',
    memberSince: 'Member since April 2021',
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Alex',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: false,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-alex-review-002.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/608x1080/6d400b7bdf/fc-member-alex-review-first-frame-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-alex-review-002.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
      _editable: '<!--#storyblok#{"name": "Video", "space": "152150", "uid": "fe7ea043-9f69-41ed-b507-cd47e55e9ee5", "id": "235920305"}-->',
    },
  },
  {
    id: '003',
    videoPreview: {
      url: 'https://a.storyblok.com/f/152150/2160x3840/8edb941262/fc-review-christina-preview-001.png',
      alt: "FightCamp user Christina's testimonial video",
    },
    customerIcon: {
      filename: 'https://a.storyblok.com/f/152150/782x938/a125d64889/fc-member-christina-profile.jpg',
      alt: 'Person kicking FightCamp bag',
    },
    customerName: 'Christina',
    memberSince: 'Member since April 2021',
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Christina',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: false,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-christina-review-002.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/608x1080/f1a8929364/fc-member-christina-review-first-frame.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-christina-review-002.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
      _editable: '<!--#storyblok#{"name": "Video", "space": "152150", "uid": "fe7ea043-9f69-41ed-b507-cd47e55e9ee5", "id": "235920305"}-->',
    },
  },
  {
    id: '004',
    videoPreview: {
      url: 'https://a.storyblok.com/f/152150/2160x3840/451981a2b4/fc-review-amanda-preview-001.png',
      alt: 'FightCamp user Amanda testimonial video',
    },
    customerIcon: {
      filename: 'https://a.storyblok.com/f/152150/1080x1350/c1f15b3f4e/fc-member-amanda-profile.jpg',
      alt: 'Customer posing in their home gym with FightCamp bag',
    },
    customerName: 'Amanda',
    memberSince: 'Member since Jan 2021',
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Amanda',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: false,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-amanda-review-002.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/608x1080/9fca13fe5f/fc-member-amanda-review-first-frame-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-member-amanda-review-002.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
      _editable: '<!--#storyblok#{"name": "Video", "space": "152150", "uid": "fe7ea043-9f69-41ed-b507-cd47e55e9ee5", "id": "235920305"}-->',
    },
  },
];

export default defineComponent({
  name: 'TestimonialVideos',
  components: {
    FiveStars,
    TestimonialCarousel,
    TestimonialSlide,
  },
  setup() {
    return {
      testimonalList: computed(() => [...testimonialData, ...testimonialData, ...testimonialData]),
      testimonialData,
    };
  },
});
